<template>
  <div>
    <van-nav-bar left-arrow left-text="返回" @click-left="goBack" title="当天新增" />
    <div class="search">
      <van-radio-group style="font-size:14px" icon-size="16px" @change="selectDateFn" v-model="dateType" direction="horizontal">
        <van-radio name="1">七天</van-radio>
        <van-radio name="2">近一月</van-radio>
        <van-radio name="3">自定义</van-radio>
        <van-radio name="4">全年</van-radio>
        <van-button type="primary" size="mini" @click="searchFn">查询</van-button>
      </van-radio-group>
    </div>
    <van-cell-group class="date-cell-group" v-show="dateType === '3'">
      <van-cell title="选择日期区间" :value="date" @click="show = true" is-link />
      <van-calendar v-model="show" type="range" :min-date="minDate" @confirm="onConfirm" />
    </van-cell-group>
    <div>
      <van-row>
        <van-col span="24">
          <div @click="dzzfjeFn" style="width: auto;height: 400px;" class="payMoney" id="payMoney"></div>
        </van-col>
        <van-col span="24">
          <div @click="dtxzazgdFn" style="width: auto;height: 400px;" class="payMoney" id="payMoney2"></div>
        </van-col>
        <van-col span="24">
          <div @click="dtxzwxgdFn" style="width: auto;height: 400px;" class="payMoney" id="payMoney3"></div>
        </van-col>
      </van-row>

    </div>
    <div class="install"></div>
    <div class="repair"></div>
  </div>
</template>

<script>
import { getUrlKey } from "@/utils/common";
export default {
  data() {
    return {
      dateType: "1",
      date: "",
      show: false,
      startDate: "",
      endDate: "",
      minDate: new Date(2020, 1, 1),
      queryData: {}, //路由需要传的参数
      value: {
        startDate: "",
        endDate: "",
      }, //路由需要传的参数
    };
  },
  mounted() {
    this.searchFn();
  },
  methods: {
    dtxzwxgdFn(e) {
      if (e.target.nodeName === "DIV" || e.target.nodeName === "SPAN") {
        this.$router.push({
          path: "/analysis/addrepairgdl",
        });
      }
    },
    dtxzazgdFn(e) {
      if (e.target.nodeName === "DIV" || e.target.nodeName === "SPAN") {
        this.$router.push({
          path: "/analysis/addInstallgdl",
        });
      }
    },
    //电子支付金额页面
    dzzfjeFn(e) {
      if (e.target.nodeName === "DIV" || e.target.nodeName === "SPAN") {
        this.$router.push({
          path: "/analysis/payDay",
          name: "payDay",
          query: {
            // date: this.queryData,
            start: this.value.startDate,
            end: this.value.endDate,
          },
        });
      }
    },
    lastMonthArr(value) {
      var d = new Date();
      var result = [];
      for (var i = 0; i < value; i++) {
        var m = d.getMonth() + 1;
        m = m < 10 ? "0" + m : m;
        //在这里可以自定义输出的日期格式
        result.push(d.getFullYear() + "-" + m);
        d.setMonth(d.getMonth() - 1);
      }
      result.splice(0, 1);
      return result.reverse();
    },

    getMyDate(value) {
      const arr = [];
      for (let i = 0; i < value; i++) {
        const time = new Date(
          new Date().setDate(new Date().getDate() + i - value)
        );
        const year = time.getFullYear();
        const month = `0${time.getMonth() + 1}`.slice(-2);
        const strDate = `0${time.getDate()}`.slice(-2);
        arr.push(`${year}-${month}-${strDate}`);
      }
      return arr;
    },
    //查询
    searchFn() {
      let xx = 0;
      let start = null;
      let end = null;
      if (this.dateType === "4") {
        let nowDate = new Date();
        let date = {
          // 获取当前年份

          year: nowDate.getFullYear(),

          //获取当前月份

          month: nowDate.getMonth() + 1,
        };
        start = date.year + "-01";
        end =
          date.year + "-" + (date.month > 9 ? date.month : "0" + date.month);
        let params = {
          openid: getUrlKey("openid", window.location.href),
          moduleId: "11",
          type: "家用",
          startYearMonth: start,
          endYearMonth: end,
          website: "",
        };
        this.postRequest("/wechat-analysis/fees-month", params).then((res) => {
          let azje = [];
          let wxje = [];
          let qnazje = [];
          let qnwxje = [];
          let azgdl = [];
          let qnazgdl = [];
          let azsbs = [];
          let qnazsbs = [];
          let wxsbs = [];
          let qnwxsbs = [];
          let wxgdl = [];
          let qnwxgdl = [];
          let date_x = [];
          for (let index = 0; index < res.data.length; index++) {
            azje.push(res.data[index].insamount);
            wxje.push(res.data[index].repamount);
            qnazje.push(res.data[index].yinsa);
            qnwxje.push(res.data[index].yrepa);
            azgdl.push(res.data[index].insorder);
            qnazgdl.push(res.data[index].yinso);
            wxgdl.push(res.data[index].reporder);
            qnwxgdl.push(res.data[index].yrepo);
            date_x.push(res.data[index].dmonth);
          }
          let mon = date_x;
          let obj = {
            title: "电子支付金额",
            rightTitle: [
              "安装电子支付",
              "去年安装电子支付",
              "维修电子支付",
              "去年维修电子支付",
            ],
            ddate: mon,
            ser: [
              {
                name: "安装电子支付",
                type: "line",
                data: azje,
              },
              {
                name: "去年安装电子支付",
                type: "line",
                data: qnazje,
              },
              {
                name: "维修电子支付",
                type: "line",
                data: wxje,
              },
              {
                name: "去年维修电子支付",
                type: "line",
                data: qnwxje,
              },
            ],
          };
          let obj2 = {
            title: "当天新增安装工单",
            rightTitle: ["安装工单量", "去年工单量"],
            ddate: mon,
            ser: [
              {
                name: "安装工单量",
                type: "line",
                data: azgdl,
              },
              {
                name: "去年工单量",
                type: "line",
                data: qnazgdl,
              },
            ],
          };
          let obj3 = {
            title: "当天新增维修工单",
            rightTitle: ["维修工单量", "去年工单量"],
            ddate: mon,
            ser: [
              {
                name: "维修工单量",
                type: "line",
                data: wxgdl,
              },
              {
                name: "去年工单量",
                type: "line",
                data: qnwxgdl,
              },
            ],
          };

          this.payMoney("payMoney", obj);
          this.payMoney("payMoney2", obj2);
          this.payMoney("payMoney3", obj3);
        });
      } else {
        if (this.dateType === "1") {
          xx = 7;
          start = this.dateUtils.getBeforeDate(xx);
          // end = this.dateUtils.formatDate(new Date());
          end = this.dateUtils.getBeforeDate(1);
        } else if (this.dateType === "2") {
          xx = 30;
          start = this.dateUtils.getBeforeDate(xx);
          // end = this.dateUtils.formatDate(new Date());
          end = this.dateUtils.getBeforeDate(1);
        } else {
          start = this.startDate;
          end = this.endDate;
        }

        let params = {
          openid: getUrlKey("openid", window.location.href),
          moduleId: "11",
          type: "家用",
          startDate: start,
          endDate: end,
        };
        this.value = params;
        this.postRequest("/wechat-analysis/fees-day-wo-website", params).then(
          (res) => {
            let azdzzf = [];
            let qnazdzzf = [];
            let wxdzzf = [];
            let qnwxdzzf = [];
            let date = [];
            let azgdl = [];
            let qnazgdl = [];
            let wxgdl = [];
            let qnwxgdl = [];
            let zdyTime = [];
            let zdyTime2 = [];
            let azsbs = [];
            let qnazsbs = [];
            let wxsbs = [];
            let qnwxsbs = [];
            for (let index = 0; index < res.data.length; index++) {
              if (res.data[index].btype === "材料") {
                azdzzf.push(res.data[index].amount);
                qnazdzzf.push(res.data[index].namount);
                azgdl.push(res.data[index].ctorder);
                qnazgdl.push(res.data[index].nct);
                azsbs.push(res.data[index].machinect);
                qnazsbs.push(res.data[index].nmachine);
                zdyTime.push(res.data[index].ddate);
              }
              if (res.data[index].btype === "维修") {
                wxdzzf.push(res.data[index].amount);
                qnwxdzzf.push(
                  res.data[index].namount ? res.data[index].namount : 0
                );
                wxgdl.push(
                  res.data[index].ctorder ? res.data[index].ctorder : 0
                );
                qnwxgdl.push(res.data[index].nct ? res.data[index].nct : 0);
                wxsbs.push(
                  res.data[index].machinect ? res.data[index].machinect : 0
                );
                qnwxsbs.push(
                  res.data[index].nmachine ? res.data[index].nmachine : 0
                );
                zdyTime2.push(res.data[index].ddate);
              }
              date.push(res.data[index].ddate);
            }
            let arr = [];
            if (this.dateType === "3") {
              arr = zdyTime == [] || zdyTime2;
            } else {
              arr = this.getMyDate(xx);
            }
            // this.queryData = arr;
            // if (arr.length > 20) {
            //   let a = arr.map((v, i) => {
            //     if ((i + 1) % 7 === 1) {
            //       return v;
            //     } else {
            //       return "";
            //     }
            //   });
            //   arr = a;
            // }
            let obj = {
              title: "电子支付金额",
              rightTitle: [
                "安装电子支付",
                "去年安装电子支付",
                "维修电子支付",
                "去年维修电子支付",
              ],
              ddate: arr,
              ser: [
                {
                  name: "安装电子支付",
                  type: "line",
                  data: azdzzf,
                },
                {
                  name: "去年安装电子支付",
                  type: "line",
                  data: qnazdzzf,
                },
                {
                  name: "维修电子支付",
                  type: "line",
                  data: wxdzzf,
                },
                {
                  name: "去年维修电子支付",
                  type: "line",
                  data: qnwxdzzf,
                },
              ],
            };
            let obj2 = {
              title: "当天新增安装工单",
              rightTitle: [
                "安装工单量",
                "去年工单量",
                "安装设备数",
                "去年设备数",
              ],
              ddate: arr,
              ser: [
                {
                  name: "安装工单量",
                  type: "line",
                  data: azgdl,
                },
                {
                  name: "去年工单量",
                  type: "line",
                  data: qnazgdl,
                },
                {
                  name: "安装设备数",
                  type: "line",
                  data: azsbs,
                },
                {
                  name: "去年设备数",
                  type: "line",
                  data: qnazsbs,
                },
              ],
            };
            let obj3 = {
              title: "当天新增维修工单",
              rightTitle: [
                "维修工单量",
                "去年工单量",
                "维修设备数",
                "去年设备数",
              ],
              ddate: arr,
              ser: [
                {
                  name: "维修工单量",
                  type: "line",
                  data: wxgdl,
                },
                {
                  name: "去年工单量",
                  type: "line",
                  data: qnwxgdl,
                },
                {
                  name: "维修设备数",
                  type: "line",
                  data: wxsbs,
                },
                {
                  name: "去年设备数",
                  type: "line",
                  data: qnwxsbs,
                },
              ],
            };

            this.payMoney("payMoney", obj);
            this.payMoney("payMoney2", obj2);
            this.payMoney("payMoney3", obj3);
          }
        );
      }
    },
    //日期区间
    formatDate(date) {
      return `${date.getFullYear()}/${
        date.getMonth() + 1 > 10
          ? date.getMonth() + 1
          : "0" + (date.getMonth() + 1)
      }/${date.getDate() > 10 ? date.getDate() : "0" + date.getDate()}`;
    },
    onConfirm(date) {
      const [start, end] = date;
      this.show = false;
      this.startDate = this.formatDate(start);
      this.endDate = this.formatDate(end);
      this.date = `${this.formatDate(start)} - ${this.formatDate(end)}`;
    },
    //选择查询日期
    selectDateFn(value) {},
    //电子支付金额
    payMoney(id, value) {
      let myChart = this.$echarts.getInstanceByDom(document.getElementById(id));
      if (myChart == null) {
        myChart = this.$echarts.init(document.getElementById(id));
      }
      myChart.setOption(
        {
          title: {
            text: value.title,
          },
          tooltip: {
            trigger: "axis",
            triggerOn: "click",
            enterable: true,
            formatter: function (params) {
              // console.log(params, this);
              let zzl = 0;
              let zzl2 = 0;
              let dataStr = `
              <div style='font-weight:700;margin:0 8px 6px'>${params[0].name}</div>`;
              if (this.dateType !== "4") {
                zzl =
                  (
                    ((params[0].data - params[1].data) / params[1].data) *
                    100
                  ).toFixed(2) * 1;
                zzl2 =
                  (
                    ((params[2].data - params[3].data) / params[3].data) *
                    100
                  ).toFixed(2) * 1;

                dataStr += `
                   <div style='margin: 0 8px'>
                  <span style='display:inline-block;margin-right:5px;width:10px;height:10px;border-radius: 10px;background-color:${
                    params[0].color
                  }';></span>
                  <span >${params[0].seriesName}</span>
                  <div>
                  <span style='color:#000;margin-left:20px;'>${
                    params[0].data
                  }<span style='color:${zzl >= 0 ? "#4790ff" : "#ff6c6c"}'>↗${
                  zzl === Infinity ? "9999" : zzl
                }%</span></span>
                  </div>
                </div>
                <div style='margin: 0 8px'>
                  <span style='display:inline-block;margin-right:5px;width:10px;height:10px;border-radius: 10px;background-color:${
                    params[1].color
                  }';></span>
                  <span >${params[1].seriesName}</span>
                  <div>
                  <span style='color:#000;margin-left:20px;'>${
                    params[1].data
                  }</span>
                  </div>
                </div>
                <div style='margin: 0 8px'>
                  <span style='display:inline-block;margin-right:5px;width:10px;height:10px;border-radius: 10px;background-color:${
                    params[2].color
                  }';></span>
                  <span >${params[2].seriesName}</span>
                  <div>
                  <span style='color:#000;margin-left:20px;'>${
                    params[2].data
                  }<span style='color:${zzl2 >= 0 ? "#4790ff" : "#ff6c6c"}'>↗${
                  zzl2 === Infinity ? "9999" : zzl2
                }%</span></span>
                  </div>
                </div>
                <div style='margin: 0 8px'>
                  <span style='display:inline-block;margin-right:5px;width:10px;height:10px;border-radius: 10px;background-color:${
                    params[3].color
                  }';></span>
                  <span >${params[3].seriesName}</span>
                  <div>
                  <span style='color:#000;margin-left:20px;'>${
                    params[3].data
                  }</span>
                  </div>
                </div>
             
              `;
              } else {
                dataStr += `
                   <div style='margin: 0 8px'>
                  <span style='display:inline-block;margin-right:5px;width:10px;height:10px;border-radius: 10px;background-color:${params[0].color}';></span>
                  <span >${params[0].seriesName}</span>
                  <div>
                  <span style='color:#000;margin-left:20px;'>${params[0].data}</span>
                  </div>
                </div>
                   <div style='margin: 0 8px'>
                  <span style='display:inline-block;margin-right:5px;width:10px;height:10px;border-radius: 10px;background-color:${params[0].color}';></span>
                  <span >${params[1].seriesName}</span>
                  <div>
                  <span style='color:#000;margin-left:20px;'>${params[1].data}</span>
                  </div>
                </div>
                
                `;
              }

              // params.forEach((v) => {
              //   dataStr += `
              //   <div>
              //   <div style='margin: 0 8px'>
              //   <span style='display:inline-block;margin-right:5px;width:10px;height:10px;border-radius: 10px;background-color:${v.color}';></span>
              //   <span >${v.seriesName}</span>
              //   <span style='float:right;color:#000;margin-left:20px;'>${v.data}
              //   <span style='color:#4790ff;'>↗</span></span>
              //   </div>
              //   </div>
              //   `;
              // });
              return dataStr;
            }.bind(this),
          },
          legend: {
            data: value.rightTitle,
            top: "6%",
          },
          grid: {
            top: "20%",
            left: "3%",
            right: "4%",
            bottom: "3%",
            containLabel: true,
          },
          // toolbox: {
          //   feature: {
          //     saveAsImage: {},
          //   },
          // },
          xAxis: {
            type: "category",
            boundaryGap: false,
            data: value.ddate,
            // axisLabel: {
            //   //坐标轴刻度标签的相关设置。
            //   interval: function (index, value) {
            //     console.log(index, value, this.dateType);
            //     if (index % 2 === 0 && this.dateType === "2") {
            //       return false;
            //     }
            //     return true;
            //   }.bind(this),
            axisLabel: {
              interval: "auto",
              rotate: "45",
            },
          },
          yAxis: {
            type: "value",
          },
          series: value.ser,
        },
        true
      );
    },
    goBack() {
      this.$router.back();
    },
  },
};
</script>

<style lang="scss" scoped>
.search {
  background-color: #fff;
  padding: 10px 0;
  margin-top: 10px;
  .van-radio-group {
    justify-content: space-around;
    align-items: center;
  }
}
.payMoney {
  background-color: #fff;
  margin-top: 10px;
  padding-top: 10px;
}
</style>